<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 登录 -->
      <ul class="l-title" v-if="isLogin">
        <li class="t-item" :class="{ active: titleActive }" type="text" @click="goPhoneLogin">
          账号登录
        </li>
        <li class="t-item" :class="{ active: !titleActive }" type="text" @click="goPhoneLogin">
          手机登录
        </li>
      </ul>
      <!-- 注册 -->
      <ul class="l-title" v-if="!isLogin">
        <li class="t-item" :class="{ active: titleActive }" type="text" @click="goPhoneLogin">
          账号注册
        </li>
        <li class="t-item" :class="{ active: !titleActive }" type="text" @click="goPhoneLogin">
          手机注册
        </li>
      </ul>
      <!-- 登录或注册表单 -->
      <div v-show="titleActive">
        <el-form ref="loginFormRef"  :model="loginForm" :rules="loginFormRules" class="login_from"
          @keyup.enter.native="sumbitLogin()" v-if="isLogin">
          <!-- 用户名 -->
          <el-form-item prop="account">
            <el-input v-model="loginForm.account" prefix-icon="fa fa-user-o" placeholder="请输入账号" clearable>
            </el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" prefix-icon="fa fa-lock fa-lg"
              :type="passwordIcon ? 'text' : 'password'" placeholder="请输入密码">
              <i slot="suffix" :class="[passwordIcon ? 'el-icon-minus' : 'el-icon-view']"
                style="margin-top: 8px; font-size: 18px" autocomplete="auto" @click="passwordIcon = !passwordIcon" />
            </el-input>
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item prop="imgcode">
            <div class="code">
              <el-input v-model="loginForm.imgcode" type="text" prefix-icon="fa fa-id-card-o" placeholder="请输入验证码"
                size="large" />
              <img :src="imgcode" class="pictrue" @click="captchas()" />
            </div>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item class="btns">
            <el-col :span="12">
              <span>没有账号，</span>
              <el-button type="text" @click="goRegister()">
                <span style="border-bottom: 1px solid #e1251b; padding-bottom: 2px">前往注册</span>
              </el-button>
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-button type="info" size="medium" @click="sumbitLogin()">登录</el-button>
              <el-button size="medium" @click="resetLoginForm()">重置</el-button>
            </el-col>
          </el-form-item>
        </el-form>
        <!-- 账号注册表单 -->
        <register v-if="!isLogin" @goLogin="goLogin"></register>
      </div>

      <!--手机注册或登录表单 -->
      <div v-show="!titleActive">
        <el-form class="login_from" ref="phoneFormRef" :model="phoneForm" :rules="phoneFormRules"
          @keyup.enter.native="login">
          <el-form-item prop="phone">
            <el-input v-model="phoneForm.phone" prefix-icon="el-icon-mobile" placeholder="请输入手机号" clearable>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input v-model="phoneForm.code" prefix-icon="el-icon-unlock" placeholder="请输入验证码" type="text">
              <el-button class="codeBtn" type="text" @click="getCode" :disabled="getCodeBtnDisable" slot="append">
                {{ btnText }}</el-button>
            </el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button type="info" size="medium" @click="phoneLogin">登录</el-button>
            <el-button size="medium" @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import register from '../register/index.vue'
import {
  getLoginListApi,
  getVerificationCodeApi,
  phoneLoginApi
} from '@/api/login'
import Setting from '../../setting'
import {
  mapState
} from 'vuex'
export default {
  name: 'login',
  components: {
    register
  },
  data () {
    var validatorPhone = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      titleActive: true,
      isLogin: true,
      loginText: '',
      // 登录表单绑定数据对象
      loginForm: {
        account: '',
        password: '',
        imgcode: ''
      },
      phoneForm: {
        phone: '',
        code: ''
      },
      scene: 'verify',
      awaitTime: 60,
      getCodeBtnDisable: false,
      passwordIcon: false,
      codeIcon: false,
      btnText: '获取验证码',
      imgcode: Setting.apiBaseURL + '/admin/verify?' + new Date(),
      // 登录表单数据验证规则对象
      loginFormRules: {
        // 用户名验证
        account: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        // 密码验证
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        // 验证码验证
        imgcode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      },
      phoneFormRules: {
        phone: [{
          required: true,
          validator: validatorPhone,
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      }
    }
  },
  computed: {
    ...mapState(['loginStatus'])
  },
  mounted () {},
  methods: {
    // 点击重置按钮，重置登录表单
    captchas () {
      this.imgcode = Setting.apiBaseURL + '/admin/verify?' + new Date()
    },
    goRegister () {
      this.isLogin = false
      this.$refs.loginFormRef.resetFields()
    },
    // 点击登录
    async sumbitLogin () {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return
        const {
          data: res
        } = await getLoginListApi(this.loginForm)
        if (res.status !== 10000) {
          this.captchas()
          this.loginForm.imgcode = ''
          return this.$message.error(res.message)
        }
        this.$message.success('登录成功')
        localStorage.setItem('school_token', res.result.token)
        this.$store.dispatch('setToken', res.result.token)
        this.$store.commit('saveloginStatus', res.result.info.type)
        //   this.$store.commit('saveLoginC_id', res.result.info.id)
        // 通过编程式导航跳转到后台主页，路由地址是 /school/home
        this.$router.replace('/pc/b_home')
        // 关闭登录弹出框
        this.$store.commit('saveloginDialog', false)
      })
    },
    // 重置登录表单数据
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    goLogin (val) {
      this.isLogin = val
    },
    goPhoneLogin () {
      this.titleActive = !this.titleActive
    },
    // 获取验证码
    getCode () {
      this.$refs.phoneFormRef.validateField('phone', async (valid) => {
        if (valid) return
        const {
          data: res
        } = await getVerificationCodeApi(this.phoneForm.phone, this.scene)
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.$message.success(res.message)
        this.getCodeBtnDisable = true
        this.btnText = `${this.awaitTime}秒后重发`
        const that = this
        const timer = setInterval(function () {
          if (that.awaitTime > 1) {
            that.awaitTime--
            that.btnText = `${that.awaitTime}秒后重发`
          } else {
            clearInterval(timer)
            that.btnText = '获取验证码'
            that.getCodeBtnDisable = false
            that.awaitTime = 60
          }
        }, 1000)
      })
    },
    // 手机登录
    phoneLogin () {
      this.$refs.phoneFormRef.validate(async valid => {
        if (!valid) return
        const {
          data: res
        } = await phoneLoginApi(this.phoneForm)
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.$message.success(res.message)
        localStorage.setItem('school_token', res.result.token)
        this.$store.dispatch('setToken', res.result.token)
        this.$store.commit('saveloginStatus', res.result.info.type)
        //   this.$store.commit('saveLoginC_id', res.result.info.id)
        // 通过编程式导航跳转到后台主页，路由地址是 /school/home
        this.$router.replace('/pc/b_home')
        // 关闭登录弹出框
        this.$store.commit('saveloginDialog', false)
      })
    },
    // 重置手机表单
    resetForm () {
      this.$refs.phoneFormRef.resetFields()
    }
  }
}

</script>

<style lang="less" scoped>
  /deep/.el-input__inner:focus {
    border-color: @primaryColor;
  }

  .login_box {
    .l-title {
      display: flex;
      height: 50px;
      margin: 0px 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ebebeb;

      .t-item {
        width: 50%;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
      }
    }

    .active {
      border-bottom: 1px solid @primaryColor;
    }
  }

  .login_from {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .pictrue {
      height: 40px;
    }
  }

  .codeBtn {
    width: 100px;
  }

  .code {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-button {
    font-size: 16px;
  }

</style>
