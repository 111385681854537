<template>
    <div class="register">
      <!-- 注册表单 -->
      <el-form
        ref="registerFormRef"
        :model="registerForm"
        :rules="registerFormRules"
        class="login_from"
        @keyup.enter.native="addRegister()"
      >
        <!-- 用户名 -->
        <el-form-item prop="account">
          <el-input
            v-model="registerForm.account"
            prefix-icon="fa fa-user-o"
            placeholder="账号由6-18位字符组成，只能包含英文字母、数字"
            clearable
          >
          </el-input>
        </el-form-item>
        <!-- 手机 -->
        <el-form-item prop="phone">
          <el-input
            v-model="registerForm.phone"
            prefix-icon="el-icon-mobile"
            placeholder="请输入手机号"
            clearable
          >
          </el-input>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item prop="code">
          <el-input
            v-model="registerForm.code"
            prefix-icon="el-icon-unlock"
            placeholder="请输入验证码"
            type="password"
          >
            <el-button
              class="codeBtn"
              type="text"
              @click="getCode"
              :disabled="getCodeBtnDisable"
              slot="append"
            >
              {{ btnText }}</el-button
            >
          </el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            v-model="registerForm.password"
            prefix-icon="fa fa-lock fa-lg"
            :type="passwordIcon ? 'text' : 'password'"
            placeholder="密码由6-16位字符组成，可包含英文字母、数字"
          >
            <i
              slot="suffix"
              :class="[passwordIcon ? 'el-icon-minus' : 'el-icon-view']"
              style="margin-top: 8px; font-size: 18px"
              autocomplete="auto"
              @click="passwordIcon = !passwordIcon"
            />
          </el-input>
        </el-form-item>
        <!-- 确认密码 -->
        <el-form-item prop="confim_password">
          <el-input
            v-model="registerForm.confim_password"
            prefix-icon="fa fa-lock fa-lg"
            :type="confirmPasswordIcon ? 'text' : 'password'"
            placeholder="请输入确认密码"
          >
            <i
              slot="suffix"
              :class="[confirmPasswordIcon ? 'el-icon-minus' : 'el-icon-view']"
              style="margin-top: 8px; font-size: 18px"
              autocomplete="auto"
              @click="confirmPasswordIcon = !confirmPasswordIcon"
            />
          </el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-col :span="12">
            <span>已有账号，</span>
            <el-button type="text" @click="goLogin()">
              <span style="border-bottom: 1px solid #e1251b; padding-bottom: 2px"
                >前往登录</span
              >
            </el-button>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button type="info" size="medium" @click="addRegister()"
              >注册</el-button
            >
            <el-button size="medium" @click="resetRegisterForm()">重置</el-button>
          </el-col>
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
    import {
      addRegisterListApi,
      getVerificationCodeApi,
    } from '@/api/franchise'
    import Setting from '../../../setting'
    export default {
      name: "register",
      data() {
        var validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入密码'))
          } else if (value !== this.registerForm.password) {
            callback(new Error('两次输入密码不一致!'))
          } else {
            callback()
          }
        };
        var validatorPhone = function (rule, value, callback) {
          if (value === '') {
            callback(new Error('手机号不能为空'))
          } else if (!/^1\d{10}$/.test(value)) {
            callback(new Error('手机号格式错误'))
          } else {
            callback()
          }
        }
        return {
          registerForm: {
            account: '',
            phone: '',
            code: '',
            password: '',
            confim_password: ''
          },
          scene: 'verify',
          awaitTime: 60,
          getCodeBtnDisable: false,
          btnText: '获取验证码',
          passwordIcon:false,
          confirmPasswordIcon:false,
          codeIcon:false,
          imgcode: Setting.apiBaseURL + '/admin/verify?' + new Date(),
          registerFormRules: {
            // 用户名验证
            account: [{
              required: true,
              message: '请输入账号',
              trigger: 'blur'
            }],
            // 密码验证
            password: [{
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            }],
            // 确认密码验证
            confim_password: [{
                required: true,
                message: '请输入确认密码',
                trigger: 'blur'
              },
              {
                validator: validatePass,
                trigger: "blur"
              },
            ],
            phone: [{
              required: true,
              validator: validatorPhone,
              trigger: 'blur'
            }],
            code: [{
              required: true,
              message: '请输入验证码',
              trigger: 'blur'
            }]
  
          }
        }
      },
      created() {},
      methods: {
        //注册
        addRegister() {
          this.$refs.registerFormRef.validate(async (valid) => {
            if (!valid) return;
            const {
              data: res
            } = await addRegisterListApi(this.registerForm)
            if (res.status !== 10000) {
              return this.$message.error(res.message)
            }
            this.$message.success('注册成功,请登录')
            this.goLogin()
            //关闭登录弹出框
            this.$store.commit('saveloginDialog', false)
          })
        },
        //登录
        goLogin() {
          this.$emit('goLogin', 'true');
          this.resetRegisterForm()
        },
        //重置注册表单数据
        resetRegisterForm() {
          this.$refs.registerFormRef.resetFields()
        },
        //获取验证码
        getCode() {
          this.$refs.registerFormRef.validateField(['phone'], async (valid) => {
            if (valid) return;
            const {
              data: res
            } = await getVerificationCodeApi(this.registerForm.phone, this.scene)
            if (res.status != 10000) {
              return this.$message.error(res.message)
            }
            this.$message.success(res.message)
            this.getCodeBtnDisable = true
            this.btnText = `${this.awaitTime}秒后重发`
            let that = this
            let timer = setInterval(function () {
              if (that.awaitTime > 1) {
                that.awaitTime--
                that.btnText = `${that.awaitTime}秒后重发`
              } else {
                clearInterval(timer)
                that.btnText = '获取验证码'
                that.getCodeBtnDisable = false
                that.awaitTime = 60
              }
            }, 1000)
          })
        },
      }
    }
  
  </script>
  
  <style lang="less" scoped>
  .el-button {
    font-size: 16px;
  }
  
  .login_from {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .codeBtn {
    width: 100px;
  }
  </style>
  