import request from '@/libs/request'


/**
 * 注册
 */
export function addRegisterListApi(data) {
    return request({
        url: '/admin/register',
        method: 'post',
        data
    })
}

/**
 * 获取验证码
 */
export function getVerifyListApi() {
    return request({
        url: '/admin/verify',
        method: 'get',
    })
}

/**
 * 登录
 */
export function getLoginListApi(data) {
    return request({
        url: '/admin/login',
        method: 'post',
        data
    })
}

/**
 * 获取验证码
 */
export function getVerificationCodeApi(phone, scene) {
    return request({
        url: `/admin/send?phone=${phone}&scene=${scene}`,
        method: 'get',
    })
}

/**
 * 手机注册或登录
 */
export function phoneLoginApi(data) {
    return request({
        url: '/user/userlogin',
        method: 'post',
        data
    })
}