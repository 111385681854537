<template>
  <div class="home">
    <el-container>
      <el-header>
        <el-row class="header">
          <el-col :span="13" class="h-list">
            <li class="l-first">
              <router-link to="/pc/home">首页</router-link>
            </li>
            <li class="l-item">
              <router-link to="/pc/student">人才库</router-link>
            </li>
            <li class="l-item">
              <router-link to="/pc/job">职位</router-link>
            </li>
          </el-col>
          <el-col :span="11" class="h-btn" v-if="isShow">
            <!-- <el-button class="c-btn" size="medium " round @click="goLogin(2)">普通企业注册/登录</el-button>
            <el-button class="c-btn" size="medium " round @click="goLogin(1)">经营网点注册/登录</el-button> -->
            <el-button class="c-btn" size="medium" round @click="goLogin(1)">快速登录</el-button>
          </el-col>
          <el-col :span="11" class="h-btn" v-else>
            <el-button class="c-btn" size="medium " round @click="goB_home()">个人中心</el-button>
            <el-button class="c-btn" size="medium " round @click="goLoginOut()">退出登录</el-button>
          </el-col>
        </el-row>
      </el-header>
      <div class="h-image">
        <img src="../../../src/assets/images/big_logo.jpg" />
      </div>
      <el-main>
          <!-- 需要缓存的组件 -->
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <!-- 不需要缓存的组件 -->
          <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
      <!-- 底部 -->
      <div class="el-footer">
        <div class="footer">
          <div class="f-main">
            <div class="m-ul">
              <div class="m-item">
                <a href="https://serfoo.com/school">主页</a>
              </div>
              <div class="m-item">
                <a href="https://serfoo.com/home" target="_blank">十二方商城</a>
              </div>
              <div class="m-item">
                <a href="/home/about?id=38" target="_blank">十二方产业</a>
              </div>
              <div class="m-item">
                <a href="/home/admin_login" target="_blank">管理者登录</a>
              </div>
            </div>
            <div class="m-txt">
              <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2021002198号-1</a>
            </div>
          </div>
        </div>
      </div>
    </el-container>
    <!--登录弹出框 -->
    <el-dialog title="手机号注册/登录" :visible="loginDialog" center :append-to-body="true" :lock-scroll="false" width="25%"
      @close="closeDialog" v-if="loginStatus==1">
      <!-- 经营网点 -->
      <franchise-enterprise></franchise-enterprise>
    </el-dialog>
    <!--登录弹出框 -->
    <!-- <el-dialog title="普通企业注册/登录" :visible="loginDialog" center :append-to-body="true" :lock-scroll="false" width="25%"
      @close="closeDialog" v-if="loginStatus==2"> -->
      <!-- 普通企业 -->
      <!-- <general-enterprise></general-enterprise>
    </el-dialog> -->


    <!-- 右侧固定区域 -->
    <div class="floatWindow">
      <div class="list">
        <div class="item" @mouseleave="wxCodeHide">
          <div @mouseenter="wxCode">
            <div class="school icon-lianxikefu"></div>
            <div>联系客服</div>
          </div>
          <div class="itemCon" v-if="isCode">
            <div class="ewm">
              <div class="pictrue">
                <!-- <div class="arrow"></div> -->
                <img src="../../assets/images/kefu.jpg" class="bicode" />
              </div>
              <div class="tip">扫码关注企业微信</div>
            </div>
          </div>
        </div>
        <div class="item" @click="goTop">
          <div class="school icon-icon-test"></div>
          <div>回到顶部</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import generalEnterprise from '../login/index.vue'
  import franchiseEnterprise from '../franchise/franchiseLogin/index.vue'
  import {
    mapState
  } from 'vuex'
  export default {
    name: 'pc_index',
    components: {
      generalEnterprise,
      franchiseEnterprise
    },
    data() {
      return {
        isShow: true,
        isCode: false,
      }
    },
    computed: {
      ...mapState(['loginDialog', 'loginStatus'])
    },
    mounted() {
      if (localStorage.getItem('school_token')) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    },
    methods: {
      //登录
      goLogin(val) {
        this.$store.commit('saveloginStatus', val)
        this.$store.commit('saveloginDialog', true)
      },
      //退出登录
      goLoginOut() {
        this.$store.dispatch("clearToken"),
          this.$store.commit('saveloginStatus', '')
        //清除token
        localStorage.removeItem("school_token")
        localStorage.removeItem("schoolLoginStatus")
        this.isShow = true
        this.$router.go()
      },
      //进入b_home主页
      goB_home() {
        this.$router.push('/pc/b_home')
      },
      closeDialog() {
        this.$store.commit('saveloginDialog', false)
      },
      //回到顶部
      goTop() {
        (function n() {
          var t = document.documentElement.scrollTop || document.body.scrollTop;
          if (t > 0) {
            window.requestAnimationFrame(n);
            window.scrollTo(0, t - t / 5)
          }
        })()
      },
      wxCode() {
        this.isCode = true
      },
      wxCodeHide() {
        this.isCode = false
      },
    },

  }

</script>

<style lang="less" scoped>
  .el-dialog__wrapper {
    top: 20%;
  }

  /deep/.el-dialog__close {
    color: #fff;
  }

  /deep/.el-dialog__close:hover {
    color: #fff;
  }

  /deep/.el-dialog__close:focus {
    color: #fff;
  }

  /deep/.el-dialog__header {
    background: @primaryColor;

    .el-dialog__title {
      color: #fff;
    }
  }

  /deep/.el-dialog__body {
    padding: 10px 20px;
  }

  .home {
    background: #fafafa;
  }

  a {
    text-decoration: none;
  }

  .el-container {
    min-height: 100vh;
  }

  .el-header {
    height: 60px;
    line-height: 60px;
    border-bottom: 2px solid #efefef;

    .header {
      width: 1200px;
      margin: 0 auto;
      box-sizing: border-box;

      .h-btn {
        height: 60px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .c-btn:hover,
        .c-btn:focus {
          border-color: @primaryColor;
          background: @primaryColor;
          color: #fff;
        }
      }

      .h-list {
        display: flex;

        a {
          font-size: 15px;
        }

        .l-item {
          margin-left: 30px;
        }
      }
    }
  }

  .h-image {
    width: 1200px;
    margin: 0px auto;
    padding: 0;
    border-bottom: 2px solid #efefef;
    background: #fff;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .m-search {
    background: #fff;

    .s-container {
      width: 1200px;
      // height: 100px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-input {
        width: 780px;
        height: 48px;
        border: 1px solid #409eff;
        border-radius: 3px;
      }

      /deep/ .el-input__inner {
        height: 48px;
      }

      /deep/ .el-input__suffix {
        top: 15%;
      }
    }
  }

  .el-main {
    padding: 0;

    .m-container {
      width: 1200px;
      margin: 40px auto;

      .el-col {
        border-radius: 5px;
        background: #fff;
      }

      .m-content {
        height: 340px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }

      .m-list {
        height: 50px;
        line-height: 50px;

        .l-item {
          float: left;
          width: 70px;
          text-align: center;
        }
      }

      .m-footer {
        border-top: 1px dashed #e6e6e6;
        height: 45px;
        line-height: 45px;
        padding: 0px 40px;
        display: flex;
        justify-content: space-between;

        .f-icon {
          display: flex;
          font-size: 15px;

          .i-right {
            margin-left: 20px;
          }
        }
      }
    }

    .hot-jobs {
      width: 1200px;
      margin: 40px auto;
    }
  }

  .el-footer {
    background: #f2f2f2;
    border-top: 2px solid #efefef;

    .footer {
      width: 1200px;
      margin: 0 auto;
      padding: 20px 0px;

      // .f-header {
      //   height: 104px;
      //   border-bottom: 1px solid #e2e2e2;
      //   display: flex;
      //   justify-content: space-between;

      //   .f-item {
      //     width: 25%;
      //     height: 104px;
      //     display: flex;
      //     align-items: center;

      //     .picture {
      //       width: 40px;
      //       height: 40px;
      //       border: 1px solid #707070;
      //       border-radius: 50%;
      //       margin-right: 10px;
      //       line-height: 40px;
      //       text-align: center;
      //     }

      //     .txt {
      //       font-size: 13px;
      //       color: #282828;
      //     }
      //   }
      // }

      .f-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;

        .m-ul {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .m-item {
            height: 20px;
            box-sizing: border-box;
            line-height: 20px;
            border-right: 1px solid #ddd;
            text-align: center;
            padding: 0px 10px;
          }

          .m-item:last-child {
            border-right: none;
          }
        }

        .m-txt {
          text-align: center;

          span {
            font-size: 14px;
            margin-right: 10px;
            color: #282828;
          }

          a {
            font-size: 13px;
          }
        }
      }
    }
  }

  .floatWindow {
    position: fixed;
    right: 0;
    bottom: 50%;
    width: 60px;
    z-index: 99;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);

    .list {
      .item {
        position: relative;
        width: 100%;
        height: 60px;
        text-align: center;
        font-size: 12px;
        color: #5c5c5c;
        padding: 12px 0;

        &:hover {
          color: #e93323;
        }

        .school {
          margin-bottom: 10px;
          font-size: 25px;
        }

        &~.item {
          &:before {
            position: absolute;
            content: " ";
            width: 48px;
            height: 1px;
            background-color: #f0f0f0;
            top: 0;
            left: 50%;
            margin-left: -24px;
          }
        }

        .itemCon {
          right: 100%;
          position: absolute;
          top: 0;
          padding-right: 10px;

          .ewm {
            width: 140px;
            border: 1px solid #eeeeee;
            background-color: #fff;
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .tip {
              font-size: 14px;
              color: #666;
              margin-top: 5px;
            }

            .pictrue {
              width: 126px;
              height: 126px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

</style>
